<template>
  <div class="layout-subcontainer">
    <v-container fluid fill-height class="pa-3">
      <v-row class="d-sm-flex flex-sm-column">
        <v-col>
          <!-- 프레임워크 구조 설명 -->
          <p class="site-link">
            <a href="https://kr.vuejs.org/" target="_black"
              >Vue 사이트 바로가기</a
            >
            <a href="https://cli.vuejs.org/" target="_black"
              >Vue-cli 사이트 바로가기</a
            >
            <a href="https://vuetifyjs.com/en/" target="_black"
              >Vuetify 사이트 바로가기</a
            >
          </p>
          <v-tabs fixed-tabs background-color="indigo" dark v-model="tab">
            <v-tab class="text-h6"> Vue 문법 </v-tab>
            <v-tab class="text-h6"> store(Vuex) 사용 </v-tab>
            <v-tab class="text-h6"> Axios(API) 사용</v-tab>
            <v-tab class="text-h6"> 라우팅/메뉴/인증 설정 </v-tab>
          </v-tabs>
          <v-card
            class="flex-sm-grow-1 pa-5"
            elevation="2"
            max-width=""
            outlined
          >
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <!-- <v-card-title
                primary-title
                class="font-weight-bold primary--text"
              >
                프레임워크 문법
              </v-card-title> -->
                <v-simple-table class="text-h4" :light="true">
                  <thead>
                    <tr>
                      <th class="text-center" width="300px">문법</th>
                      <th class="text-center" width="120px">약어</th>
                      <th class="text-center">설명</th>
                      <th class="text-center">예제</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in basicData" :key="index">
                      <td class="text-center">{{ item.title }}</td>
                      <td class="text-center abb">
                        <v-chip v-if="item.abb">
                          {{ item.abb }}
                        </v-chip>
                      </td>
                      <td>{{ item.disc }}</td>
                      <td>
                        <ul>
                          <li v-for="(item2, index2) in item.ex" :key="index2">
                            {{ item2 }}
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-tab-item>

              <v-tab-item class="pd-5">
                <!-- <v-card-title
                primary-title
                class="font-weight-bold primary--text mt-8"
              >
                store(Vuex) 사용법
              </v-card-title> -->
                <p class="red--text">
                  * import { mapState, mapGetters, mapActions } from "vuex" 를
                  선언
                </p>
                <v-simple-table class="text-h4">
                  <thead>
                    <tr>
                      <th class="text-center" width="300px">요소</th>
                      <th class="text-center">설명</th>
                      <th class="text-center">사용법</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in storeData" :key="index">
                      <td class="text-center">{{ item.title }}</td>
                      <td>{{ item.disc }}</td>
                      <td>
                        <ul>
                          <li v-for="(item2, index2) in item.ex" :key="index2">
                            {{ item2 }}
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-tab-item>

              <v-tab-item>
                <!-- <v-card-title
                primary-title
                class="font-weight-bold primary--text mt-8"
              >
                Axios 사용법
              </v-card-title> -->
                <p class="red--text">
                  * import api from "@/store/apiUtil" 를 선언 후 api.으로 호출
                </p>
                <v-simple-table class="text-h4">
                  <thead>
                    <tr>
                      <th class="text-center" width="300px">메소드</th>
                      <th class="text-center">설명</th>
                      <th class="text-center">사용법</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in axiosData" :key="index">
                      <td class="text-center">{{ item.title }}</td>
                      <td>{{ item.disc }}</td>
                      <td>
                        <ul>
                          <li v-for="(item2, index2) in item.ex" :key="index2">
                            {{ item2 }}
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-tab-item>

              <v-tab-item>
                <v-simple-table class="text-h4">
                  <thead>
                    <tr>
                      <th class="text-center" width="300px">설정</th>
                      <th class="text-center">설명</th>
                      <th class="text-center">사용법</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in routerData" :key="index">
                      <td class="text-center">{{ item.title }}</td>
                      <td>{{ item.disc }}</td>
                      <td>
                        <ul>
                          <li v-for="(item2, index2) in item.ex" :key="index2">
                            {{ item2 }}
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row></v-container
    >
  </div>
</template>

<script>
export default {
  name: "HelpMenual",
  components: {
    //   HelloWorld,
  },
  data: () => ({
    tab: null,
    basicData: [
      {
        title: "v-for",
        abb: "",
        disc: "반복 렌더링",
        ex: [
          '<li v-for="(item, index2) in arrayData" :key="index">{{item.title}}</li>',
        ],
      },
      {
        title: "v-if, v-else-if, v-else",
        abb: "",
        disc: "조건부 렌더링",
        ex: [
          '<div v-if="type===0"></div>',
          '<div v-else-if="type===1"></div>',
          "<div v-else></div>",
        ],
      },
      {
        title: "v-show",
        abb: "",
        disc: "화면에 표시 여부(화면에 렌더링은 되어 있으나 표시는 하지 않음 - display CSS 속성)",
        ex: ['<div v-show="viewYn"></div>'],
      },
      {
        title: "v-on",
        abb: "@",
        disc: "이벤트 핸들러",
        ex: [
          '<button @click="search()"></button>',
          '<input v-on:keyup.enter="submit" />',
        ],
      },
      {
        title: "v-model",
        abb: "",
        disc: "데이터 바인딩(input, select, textarea 사용)",
        ex: ['<input v-model:"getUserState" />'],
      },
      {
        title: "v-bind",
        abb: ":",
        disc: "속성 바인딩(tag 및 components 속성)",
        ex: [
          '<img :src="imageSrc">',
          '<div :class="{red: isRed}" :style="{fontSize: 15px}"></div>',
          '<menu-list :item="item" />(자식컴포넌트로 데이터 바인딩. 자식컴포넌트에서는 props속성으로 todo 데이터를 받아서 사용 )',
        ],
      },
      {
        title: "v-slot",
        abb: "#",
        disc: "부모 컴포넌트와 데이터 바인딩",
        ex: [
          '자식: <slot name="sample1"></slot>',
          "부모: <template v-slot:sample1>insert sample1 data</template>",
          "자식 컴포넌트 sample1 slot 부분에 insert sample1 data 가 출력",
        ],
      },
      {
        title: "",
        abb: "$",
        disc: "전역 객체 속성(public)",
        ex: [
          "vm.$router(라우터)",
          "vm.$emit(이벤트)",
          "vm.$data, vm.$props(데이터 옵션)",
          "vm.$el(Element)",
          "vm.$parent, vm.$children, vm.$root(부모,자식,최상위부모)",
          "vm.$attrs(HTML속성)",
          "vm.$set, vm.$delete(속성 추가/삭제)",
          "vm.$on, vm.$once, vm.$off(이벤트 등록/단일 이벤트 등록/이벤트 삭제)",
        ],
      },
      {
        title: "",
        abb: "_",
        disc: "지역 객체 속성(private)",
        ex: ['this._userName = "홍길동"'],
      },
      {
        title: "",
        abb: "@",
        disc: "@는 현재 프로젝트의 client/src/까지의 최종 경로",
        ex: ['import Menual from "@/views/help/Menual"; '],
      },
    ],
    storeData: [
      {
        title: "State",
        disc: "컴포넌트간 공유를 위한 데이터 속성을 의미(메모리에 저장됨으로 새로고침시 소멸됨)",
        ex: [
          "computed 에서만 사용 가능함",
          "this.$store.state.items",
          "...mapState({ items: state => state.items, })",
          "State에 직접 접근은 가능하나 getter를 사용하여 가져오는것을 원칙으로 함",
        ],
      },
      {
        title: "Getters",
        disc: "State를 가져오기 위해 사용. 날짜포맷과 같이 컴포넌트에서 computed(계산된 값) 사용시 공통으로 만들어도 사용 가능함 ",
        ex: [
          "computed 에서만 사용 가능함",
          "this.$store.getters['userStore/GE_USER_NAME'];",
          "...mapGetters({ doneCount: 'item/doneTodosCount' })",
        ],
      },
      {
        title: "Mutations",
        disc: "State의 값을 업데이트할 때 사용",
        ex: [
          "methods 에서만 사용 가능함",
          "동기식처리만 사용",
          "this.$store.commit('userStore/MU_USER_STATE',{ustate:'none'})",
          "...mapMutations({ add: 'item/increment'})",
          "this.add()를 this.$store.commit('item/increment')에 매핑",
        ],
      },
      {
        title: "Actions",
        disc: "비동기식 처리를 위한 요소. Action으로 이벤트를 받아 API를 통신 후 State의 데이터 변경은 Mutations을 사용하여 처리",
        ex: [
          "methods 에서만 사용 가능함",
          "this.$store.dispatch('commonStore/AC_COMMON_LOGIN',{uid:this.uid, upwd:this.upwd})",
          "...mapActions({ add: 'item/increment'})",
          "this.add()를 this.$store.dispatch('item/increment')에 매핑",
        ],
      },
    ],
    axiosData: [
      {
        title: "GET",
        disc: "데이터 호출",
        ex: ["api.get(url[, config]"],
      },
      {
        title: "POST",
        disc: "데이터 등록",
        ex: ["api.post(url[, data[, config]])"],
      },
      {
        title: "PATCH",
        disc: "데이터 업데이트",
        ex: ["api.patch(url[, data[, config]])"],
      },
      {
        title: "DELETE",
        disc: "데이터 삭제",
        ex: ["api.delete(url[, config])"],
      },
    ],
    routerData: [
      {
        title: "라우팅",
        disc: "라우팅을 설정",
        ex: [
          "페이지 컴포넌트 파일을 생성",
          "/src/router/index.js파일에 라우팅 설정을 추가",
          "추가시 인증여부(beforeAuth)와 레이아웃 컴포넌트(*Layout)을 고려하여 적용",
        ],
      },
      {
        title: "메뉴",
        disc: "메뉴를 추가",
        ex: [
          "/src/layouts/*/Aside.vue파일에 메뉴를 추가",
          "items에 데이터를 추가하여 적용(items 옵션은 Aside.vue 파일내에 주석으로 설명)",
          // "items 옵션 설명" +
          // "title: aside에 보여지는 메뉴명 설정(iframe이 false인 경우 필수)",
          // icon: aside에 보여지는 아이콘 설정(iframe이 false인 경우 필수)
          // to: aside에 보여진 후 클릭하면 이동할 링크 경로(iframe이 false인 경우 필수)
          // iframe: 전체화면 또는 tab으로 보여질지 선택(true이면 tab으로 보여짐. default: true)
          // id: 컴포넌트의 name 값 (iframe이 true인 경우 사용)
          // tabIcon: tab에 보여질 아이콘 설정(iframe이 true인 경우 사용. 빈값인 경우 아이콘 생략)"
        ],
      },
      {
        title: "인증",
        disc: "사용자 인증을 설정",
        ex: [
          "/src/router/index.js파일에 beforeAuth에 인증을 설정",
          "jwt token을 사용",
          "로그인시 token을 발급받아 store 및 sessionStorage에 token을 저장",
          "페이지 이동시 store에 token을 확인한 후 없으면 sessionStorage를 확인 후 없으면 /login으로 라우팅",
          "페이지 로딩(새로고침)시 /home이동을 기본으로 하며 /home으로 이동시 token을 확인 후 없으면 /login으로 라우팅 처리함",
        ],
      },
    ],
  }),
};
</script>

<style scoped>
table tr th {
  font-size: 16px !important;
  background-color: #e3f2fd;
  color: #1976d2 !important;
  border-top: 1px solid #1976d2;
}

table tr td {
  font-size: 16px !important;
  color: #000;
  border-bottom: 1px solid #1976d2;
}

table tr td.abb {
  font-weight: bold;
}

.site-link a {
  margin-right: 25px;
  color: orange;
}
</style>
